import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* Defaults */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html, body {
    height: 100%;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root {
    isolation: isolate;
    height: 100%;
  }

  /* App base styles */
  :root {
    --primary: hsl(11deg 94% 64%);
    --primary-light: hsl(16deg 100% 66%);
    --white: #ffffff;
    --black: #000000;
    --black-light: #0F1114;
    --grey: #F6F6F6;
    --grey-dark: #454545;
    --box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  }

  html, body {
    color: var(--black-light);
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    background-color: var(--grey);
  }

  button {
    font-size: 1rem;
    padding: 4px 12px;
    border-radius: 4px;
    border: 2px solid transparent;
    background-color: var(--primary);
    color: var(--white);
    cursor: pointer;

    &:hover {
      background-color: var(--primary-light);
    }

    &:focus {
      outline-color: var(--primary);
      outline-offset: 4px;
    }
  }

  input {
    font-size: 1rem;
    padding: 5px 12px;
    appearance: none;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &:read-only {
      border-color: transparent;
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`;

export { GlobalStyles };
