import styled from 'styled-components';
import { useMsal } from '@azure/msal-react';
import { RequestPasswordForm } from '../components/RequestPasswordForm';
import { RequestHistoryTable } from '../components/RequestHistoryTable';
import { allowedUserGroup, devUserGroup } from '../authConfig';

function Home() {
  const { instance, accounts } = useMsal();
  const groups = accounts[0]?.idTokenClaims.groups;
  const hasUserGroupPermission = groups?.includes(allowedUserGroup);
  const isDev = groups?.includes(devUserGroup);

  return (
    <Wrapper>
      <h1>System Access Request</h1>
      <Profile>
        <h2>{accounts[0]?.name}</h2>
        <button
          type="button"
          onClick={() => {
            instance.logoutRedirect();
          }}
        >
          Sign out
        </button>
      </Profile>
      {hasUserGroupPermission ? (
        <div>
          <Card>
            <RequestPasswordForm showProcessSecret={isDev} />
          </Card>
          <Card>
            <RequestHistoryTable />
          </Card>
        </div>
      ) : (
        <Card>
          <PermissionError>
            Account has insufficient permission.
          </PermissionError>
        </Card>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 16px;
`;

const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const Card = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: 8px;
  margin: 8px;
  box-shadow: var(--box-shadow);
`;

const PermissionError = styled.p`
  padding: 16px;
  color: red;
`;
export { Home };
