import { useMsal } from '@azure/msal-react';
import styled from 'styled-components';
import { loginRequest } from '../authConfig';

function Login() {
  const { instance } = useMsal();

  return (
    <Wrapper>
      <LoginBox>
        <h1>System Access Request</h1>
        <h2>Please Sign In</h2>
        <button
          type="button"
          onClick={() => instance.loginRedirect(loginRequest)}
        >
          Sign in
        </button>
      </LoginBox>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  max-width: 600px;
  margin-top: 24px;

  h1,
  h2 {
    margin-bottom: 16px;
  }
`;

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
`;

export { Login };
