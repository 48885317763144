import { useState } from 'react';
import styled from 'styled-components';

function CopyInput({ value, placeholder, disabled }) {
  const [copied, setCopied] = useState(false);

  return (
    <Wrapper>
      <input readOnly placeholder={placeholder} value={value} />
      <InputElement>
        <button
          type="button"
          disabled={disabled}
          onClick={() => {
            navigator.clipboard.writeText(value);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 1000);
          }}
        >
          {copied ? 'Copied!' : 'Copy'}
        </button>
      </InputElement>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 350px;

  input {
    width: 100%;
    padding-right: 4.5rem;
  }
`;

const InputElement = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100%;
  width: 4.5rem;

  button {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    font-weight: bold;
    font-size: 12px;
    padding: 3px 8px;

    &:hover {
      background-color: var(--white);
    }
  }
`;

export { CopyInput };
