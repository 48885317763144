import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import styled from 'styled-components';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { GlobalStyles } from './GlobalStyles';

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <Wrapper>
        <AuthenticatedTemplate>
          <Home />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </Wrapper>

      <GlobalStyles />
    </MsalProvider>
  );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
`;

export { App };
