import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useApi } from '../hooks/useApi';
import { API } from '../constants/api';

function RequestHistoryTable() {
  const [requestHistory, setRequestHistory] = useState([]);

  const { apiClient } = useApi();

  async function fetchHistory() {
    try {
      const res = await apiClient(API.REQUESTS_LOG);
      setRequestHistory(res.data.requests);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Table>
        <thead>
          <TableHeader>
            <th colSpan={2}>Request history</th>
            <th colSpan={1}>
              <Refresh onClick={() => fetchHistory()}>&#x21bb;</Refresh>
            </th>
          </TableHeader>
          <tr>
            <th>User</th>
            <th>Client</th>
            <th>Date/Time</th>
          </tr>
        </thead>

        <tbody>
          {requestHistory.map((req) => (
            <tr key={req.requested_at}>
              <td>{req.email}</td>
              <td>{req.client}</td>
              <td>{format(new Date(req.requested_at), 'dd-MM-yyyy HH:mm')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
`;

const Table = styled.table`
  width: 100%;
  th,
  td {
    padding: 4px;
  }
  tbody {
    color: var(--grey-dark);
  }
`;

const TableHeader = styled.tr`
  th {
    padding-bottom: 8px;
  }
`;

const Refresh = styled.button`
  font-weight: normal;
  font-family: Lucida Sans Unicode;
`;

export { RequestHistoryTable };
