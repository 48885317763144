import { useState } from 'react';
import styled from 'styled-components';
import { useApi } from '../hooks/useApi';
import { API } from '../constants/api';
import { CopyInput } from './CopyInput';

function RequestPasswordForm({ showProcessSecret }) {
  const [client, setClient] = useState('');
  const [secrets, setSecrets] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { apiClient } = useApi();

  async function fetchPassword(e) {
    e.preventDefault();
    setSecrets({});
    setErrorMessage('');

    if (client) {
      setLoading(true);
      try {
        const reqConfig = showProcessSecret
          ? {
              headers: {
                developer: 'developersdevelopersdevelopers',
              },
            }
          : null;
        const res = await apiClient.get(API.REQUEST_SECRET + client, reqConfig);
        setSecrets(res.data);
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <form onSubmit={fetchPassword}>
      <ClientField htmlFor="client">
        <span>Enter client: </span>
        <input
          id="client"
          placeholder="Client"
          value={client}
          onChange={(e) => {
            setClient(e.target.value);
          }}
        />
      </ClientField>

      <PasswordRequest>
        <button type="submit" disabled={loading}>
          Get system password
        </button>
        <CopyInput
          value={secrets.secret || ''}
          placeholder={loading ? 'Loading...' : 'Password'}
          disabled={!secrets.secret}
        />

        {showProcessSecret ? (
          <CopyInput
            value={secrets.process_secret || ''}
            placeholder={loading ? 'Loading...' : 'Process secret'}
            disabled={!secrets.process_secret}
          />
        ) : null}
      </PasswordRequest>
      <Error>{errorMessage}</Error>
    </form>
  );
}

const PasswordRequest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
`;

const ClientField = styled.label`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;

  span {
    font-weight: bold;
  }
`;

const Error = styled.p`
  color: red;
  margin-bottom: 8px;
`;

export { RequestPasswordForm };
