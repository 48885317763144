import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

function useApi() {
  const { instance, accounts } = useMsal();

  async function getToken() {
    const tokenResponse = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    return tokenResponse.accessToken;
  }

  const apiClient = axios.create();

  apiClient.interceptors.request.use(
    async (config) => {
      const newConfig = { ...config };
      const token = await getToken();

      newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
    },
    (error) => Promise.reject(error)
  );

  return { apiClient };
}

export { useApi };
